import React, { useContext } from "react"
import styled from "styled-components"
import Check from "../components/icons/Check"
import Seo from "../components/seo"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
// @ts-ignore
import melodyImage from "../images/melody.jpg"
import arrowImage from "../images/teal_arrow.png"
import { Context } from "../provider"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
  align-items: center;
  color: ${colors.creamLight};
  background: white;
  padding: 24px 48px;
  font-family: "Founders Grotesk";

  .pageHeader {
    font-size: 30px;
    color: black;
    background-position: bottom;
    background-size: cover;
    text-align: center;

    @media ${breakpoints.tablet} {
      font-size: 45px;
      max-width: 600px;
    }
  }

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 18px;
    margin-bottom: 30px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  ol li {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    color: black;
    font-size: 18px;
    font-weight: 500;
  }

  label {
    color: black;
  }

  .offersContainer {
    background-color: #FAFAFA;
    padding: 12px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;
    align-items: center;
  }

  .checkbox {
    padding: 0px;
    margin: 0px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  .tipText {
    color: #1D616B;
    position: absolute;
    top: 28px;
    left: 54px;
    width: 150px;
    text-align: center;
  }

  .offersTipContainer {
    position: relative;
  }

  .arrowTextContainer {
    position: absolute;
    top: -25px;
    left: 90%;
    width: 300px;
    flex-direction: row;
  }

  .arrowImage {
    width: 80px;
    height: 70px;
  }

  button {
    background-color: #fb8f35;
    color: black;
    border: none;
    padding: 12px 24px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width: 227px;
  }

  .quote {
    font-size: 12px;
    font-weight: 500;
    color: black;
    text-align: center;
    font-style: italic;
    margin-top: 36px;

    @media ${breakpoints.tablet} {
      font-size: 16px;
      max-width: 600px;
    }
  }

  .imageContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }

  .image {
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;

    @media ${breakpoints.tablet} {
      width: 50px;
      height: 50px;
    }
  }

  .imageTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${breakpoints.tablet} {
      max-width: 600px;
    }
  }

  .imageTextContainer h3 {
    font-size: 12px;
    font-weight: 600;
    color: black;
    margin: 0;

    @media ${breakpoints.tablet} {
      font-size: 14px;
    }
  }

  .imageTextContainer p {
    font-size: 12px;
    font-weight: 400;
    color: #93948f;
    margin: 0;

    @media ${breakpoints.tablet} {
      font-size: 14px;
    }
  }

`


export function Head() {
  return <Seo title={"Drømme Sample"} />
}

const CART_CREATE = `
  mutation cartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`
export default function GenericTemplate() {

  const { clearCart, addToCart } = useContext(Context)



  function checkout() {
    const client = require("graphql-client")({
      url: "https://drink-dromme.myshopify.com/api/2022-07/graphql.json",
      headers: {
        "X-Shopify-Storefront-Access-Token": "78a8fb2c4843e3c62aaee3fad5a30a9c",
      },
    })

    const globalId = "gid://shopify/ProductVariant/45407821889688"

    return client
      .query(CART_CREATE, {
        input: {
          lines: [{
            merchandiseId: globalId,
            quantity: 1,
          }],
          note: "Sample Order",
        },
      })
      .then(res => {
        window.location = res.data.cartCreate.cart.checkoutUrl
      })
  }
  return (
    <Wrapper>

      <h1 className="pageHeader">Join 1,299 connoisseurs and get a Drømme drop for $0.99</h1>
      <ol>
        <li><Check />Sample each Drømme flavor</li>
        <li><Check />Free shipping</li>
        <li><Check />15% coupon</li>
        <li><Check />One signup</li>
        <li><Check />Takes less than 1 minute</li>
      </ol>

      <div className="offersTipContainer">
        <div className="offersContainer">
          <input className="checkbox"  checked disabled type="checkbox" />
          <label>Email me with news and offers</label>
        </div>

        <div className="arrowTextContainer">
          <img src={arrowImage} alt="arrow" className="arrowImage" />
          <p className="tipText">just leave this checked for a discount code.</p>
        </div>



      </div>

      <button onClick={checkout}>
        Upgrade my cocktail
      </button>

      <p className="quote">“I tried the nonalcoholic “new fashioned” made with muddled orange,
        cherry, Earl Grey extract and a <strong>zero proof spirit called Dromme,
          which hit the spot</strong> the way a traditional cocktail would.”
      </p>

      <div className="imageContainer">
        <img src={melodyImage} alt="Sample" className="image" />
        <div className="imageTextContainer">
          <h3>Melody Baetens</h3>
          <p>Restaurant Critic, The Detroit News</p>
        </div>
      </div>

    </Wrapper>

  )
}
